@import "../../designTokens";

.inventory-tab-panel-container {
  height: 100%;
  margin-top: $spacing-x-small;

  .tab-panel-content {
    height: calc(100% - 41px);
    border: solid 2px $accentColor;
    overflow: hidden;
  }

  .tab-panel-content.box {
    padding: 0;
  }

  .tab-pane {
    height: 100%;

  }

  .nav-tabs {
    border-bottom-width: 0;
    padding-left: 20px;

    .nav-link.active {
      background-color: rgb(25, 71, 86);
      color: #ddd;
      border-color: $accentColor;
    }

    .nav-link {
      color: #ddd;
      cursor: pointer;
      background-color: $backgroundColorAccentDarkTransparent;
      border-color: $accentColor;
    }

    .nav-link:hover {
      border-color: $accentColor;
    }
  }
}