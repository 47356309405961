@import "../../designTokens";

.stack-box-container {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0 1rem 1rem 0;
  max-height: 116px;
  background-color: $backgroundColorAccentDarkTransparent;
  > * {
    padding-bottom: $spacing-xx-small;
  }
}