@import "../../designTokens";

.inventory-container {
  height: 100%;
  background-color: $backgroundColorAccent;
  border-radius: 1rem 1rem;
}

.inventory-canvas {
  background-color: $backgroundColorDarkBlue;
}
.canvas-header {
  color: #ddd;
}
.canvas-body {
  color: #bbb;
}

.inventory {
  height: 100%;
  color: #ddd;
  display: flex;
  flex-direction: column;

  .table-heading {
    width: 100%;
    font-weight: bold;
    color: #bbb;
    font-size: small;
    padding-bottom: $spacing-x-small;
  }
}

.equipment-list {
  background-color: $backgroundColorAccent;
  margin: 0;
  padding: 5px;
}

.equipment-table {
  background-color: $backgroundColorAccent;
  &.add-dialog {
    th {
      text-align: left;
    }
    tbody {
      text-align: left;
      td {
        padding: $spacing-x-small 0 $spacing-x-small 0;
        text-align: left;
      }
      td:first-child {
        text-align: left;
      }
    }
  }

  th {
    border-bottom: 1px solid $accentColor;
    background-color: $backgroundColorAccent;
    color: #bbb;
    font-size: small;
    padding: $spacing-x-small 0 $spacing-x-small 0;

    td:first-child {
      font-weight: bold;
    }
  }
  tbody {
    td {
      text-align: left;
      padding: 0;
      vertical-align: middle;
    }
    .add-button-cell {
      padding: $spacing-x-small;
    }
    td:first-child {
      text-align: center;
    }
  }
  td {
    border-color: $accentColor;
    background-color: $backgroundColorAccent;
    color: #ddd;
    font-size: small;
  }
}

.add-button {
  padding: 0 $spacing-xx-small;
  background-color: $accentColorDark;
  font-size: small;
  border-color: $accentColor;
  color: #ddd;
}

.no-border {
  border: none;
  td {
    border: none;
  }
}

.delete-button {
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  .icon {
    height: 18px;
    width: 18px;
    fill: #bbb;

  }
  &:hover {
    background-color: transparent;
  }
}


