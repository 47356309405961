@import "../../designTokens";

.hp-box-container {
  width: 100%;
  display: flex;
  //justify-content: center;
}

.hp-box {
  width: 220px;
  height: 115px;
  background-image: url("../../assets/rectangle3.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  .hp-total-div {
    display: flex;
    font-size: 28pt;
    color: #add;
    padding-top: 18px;
    text-align: center;
    align-items: center;
  }

  .hp-current-input {
    font-size: 28pt;
    height: 40px;
    max-width: 90px;
    margin-left: 20px;
    -webkit-appearance: 'none';
    -moz-appearance: 'textfield'
  }

  .hp-current-span {
    font-size: 28pt;
    color: #add;
    margin-left: 40px;
  }

  .hp-divider {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }

  .hp-label {
    flex: 1;
    display: flex;
    align-items: flex-end;
    padding-left: 30px;
    padding-bottom: 5px;
    color: #bbb;
    font-size: 10pt;
  }
}